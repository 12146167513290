import { useWaitlistStore } from "@/store/useWaitlist";
import { motion } from "framer-motion";
import { HiArrowRight, HiUsers } from "react-icons/hi";
import { useEffect, useState } from "react";
import { useSignIn, useUser, SignIn } from "@clerk/nextjs";
import { useRouter } from "next/router";
import api from "@/lib/axios";
import { BackgroundBeams } from "@/components/ui/background-beams";
import { toast } from "sonner";

export default function WaitlistPage() {
  const { waitlistCount, setWaitlistCount } = useWaitlistStore();
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Store fields
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("abc");

  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [refetchWaitlistCount, setRefetchWaitlistCount] = useState(false);

  const { signIn, isLoaded: signInLoaded } = useSignIn();
  const router = useRouter();
  const user = useUser();

  useEffect(() => {
    const fetchWaitlistCount = async () => {
      try {
        const response = await api.post("/waitlist/count");
        const data = await response.data;
        setWaitlistCount(data.count);
      } catch (error) {
        console.error("Error fetching waitlist count:", error);
      }
    };
    fetchWaitlistCount();
  }, [refetchWaitlistCount]);

  const handleWaitlistJoin = async () => {
    const resp = await api.post("/waitlist/add", {
      email,
      name,
      org: organization,
      refid: "",
    });

    if (resp.status === 200) {
      if (resp.data.isNew) {
        setRefetchWaitlistCount(!refetchWaitlistCount);
        setIsConfettiActive(true);

        setIsSuccess(true);
        setTimeout(() => {
          setIsConfettiActive(false);
        }, 5000);
      } else {
        toast.info("Email already exists in waitlist", {
          position: "top-center",
        });
      }
    }
  };

  const handleGoogleSignIn = async () => {
    if (!signInLoaded) return;

    try {
      setError("");
      await signIn.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: "/sso-callback",
        redirectUrlComplete: "/",
      });
    } catch (err: any) {
      console.error("Error signing in with Google:", err);
      if (err.errors && err.errors.length > 0) {
        setError(err.errors[0].message);
      } else {
        setError("Failed to sign in with Google. Please try again.");
      }
    }
  };

  // Derived booleans to conditionally show each field/button
  const showNameField = email.trim().length > 0;
  const showOrgField = name.trim().length > 0;
  const showJoinButton = name.trim().length > 0;

  return (
    <div className="relative isolate min-h-screen overflow-hidden bg-gradient-to-br from-zinc-950 via-zinc-900 to-black">
      {/* Confetti */}
      {isConfettiActive && (
        <div className="pointer-events-none fixed inset-0 z-50">
          {Array.from({ length: 50 }).map((_, index) => (
            <motion.div
              key={index}
              className="absolute h-2 w-2 rounded-full"
              initial={{
                top: "-10%",
                left: `${Math.random() * 100}%`,
                backgroundColor: ["#6366f1", "#a855f7", "#ec4899"][
                  Math.floor(Math.random() * 3)
                ],
              }}
              animate={{
                top: "110%",
                left: `${Math.random() * 100}%`,
                rotate: Math.random() * 360 * 2,
              }}
              transition={{
                duration: 2 + Math.random() * 2,
                ease: "easeOut",
                delay: Math.random(),
              }}
            />
          ))}
        </div>
      )}

      {/* Header */}
      <motion.header
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="fixed left-0 right-0 top-0 z-50 border-b border-zinc-800/30 bg-zinc-900/30 backdrop-blur-xl"
      >
        <div className="mx-auto flex h-16 max-w-7xl items-center justify-between px-6">
          <motion.div
            className="flex items-center"
            whileHover={{ scale: 1.02 }}
          >
            <h1 className="text-2xl font-bold text-white">Creatr</h1>
          </motion.div>

          <motion.a
            href="https://discord.gg/FzFAS6BCF8"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 rounded-lg bg-indigo-600 px-4 py-2 font-medium text-white shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <span>Join Discord</span>
          </motion.a>
        </div>
      </motion.header>

      {/* Main container - adjust height and add overflow handling */}
      <div className="min-h-screen w-full overflow-y-auto px-4 py-8 md:py-20">
        <div className="mx-auto flex min-h-[calc(100vh-80px)] w-full items-center justify-center">
          <div className="w-full max-w-2xl space-y-4 md:space-y-6">
            {/* Welcome Message */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="space-y-3 text-center md:space-y-4"
            >
              <h2 className="text-xl font-extrabold leading-tight tracking-tight sm:text-2xl md:text-4xl">
                <span className="bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 bg-clip-text text-transparent">
                  Welcome to Creatr
                  <br />
                  Enter your details for early access
                </span>
              </h2>
              <p className="mx-auto max-w-lg text-sm font-medium text-zinc-400 sm:text-base md:text-xl">
                With Creatr you can Describe, Design, Develop
                <br className="hidden md:block" />
                and Deploy products at warp speed
              </p>
            </motion.div>

            {/* Card */}
            <div className="mx-auto max-w-md">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="relative z-40 overflow-hidden rounded-xl border border-zinc-800/50 bg-zinc-900 p-4 shadow-2xl backdrop-blur-xl md:rounded-2xl md:p-8"
              >
                <motion.div
                  key="waitlist"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="space-y-4 md:space-y-6">
                    {/* Current Waitlist Count */}
                    <motion.div
                      className="relative flex flex-col items-center justify-center overflow-hidden rounded-xl border border-zinc-700/30 bg-zinc-800/30 p-4 backdrop-blur-xl md:rounded-2xl md:p-8"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.3 }}
                    >
                      <div className="mb-2 flex items-center gap-2 md:mb-4 md:gap-3">
                        <HiUsers className="h-5 w-5 text-indigo-400 md:h-6 md:w-6" />
                        <span className="text-sm font-medium text-zinc-400 md:text-base">
                          Current Waitlist
                        </span>
                      </div>

                      <motion.div
                        className="relative"
                        whileHover={{ scale: 1.05 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                      >
                        <motion.span
                          className="block bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 bg-clip-text text-4xl font-bold text-transparent md:text-6xl"
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.5 }}
                        >
                          {waitlistCount}
                        </motion.span>
                      </motion.div>

                      <motion.p
                        className="mt-2 text-xs font-medium text-zinc-500 md:mt-4 md:text-sm"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.7 }}
                      >
                        Limited spots available
                      </motion.p>
                    </motion.div>

                    {/* Form Fields */}
                    {!isSuccess ? (
                      <div className="space-y-3 md:space-y-4">
                        {/* Email */}
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <label
                            htmlFor="email"
                            className="mb-1 block text-xs font-medium text-zinc-400 md:text-sm"
                          >
                            Email
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="w-full rounded-lg border border-zinc-700/50 bg-zinc-800/50 px-3 py-2 text-sm text-white outline-none transition-all placeholder:text-zinc-500 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 md:px-4 md:py-3 md:text-base"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </motion.div>

                        {/* Name */}
                        {showNameField && (
                          <motion.div
                            key="nameField"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                          >
                            <label
                              htmlFor="name"
                              className="mb-1 block text-xs font-medium text-zinc-400 md:text-sm"
                            >
                              Name
                            </label>
                            <input
                              id="name"
                              type="text"
                              placeholder="Enter your name"
                              className="w-full rounded-lg border border-zinc-700/50 bg-zinc-800/50 px-3 py-2 text-sm text-white outline-none transition-all placeholder:text-zinc-500 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 md:px-4 md:py-3 md:text-base"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </motion.div>
                        )}

                        {/* Contact */}
                        {/* {showOrgField && ( */}
                        {/*   <motion.div */}
                        {/*     key="contactField" */}
                        {/*     initial={{ opacity: 0 }} */}
                        {/*     animate={{ opacity: 1 }} */}
                        {/*     transition={{ duration: 0.3 }} */}
                        {/*   > */}
                        {/*     <label */}
                        {/*       htmlFor="contact" */}
                        {/*       className="mb-1 block text-xs font-medium text-zinc-400 md:text-sm" */}
                        {/*     > */}
                        {/*       LinkedIn/X or Phone Number */}
                        {/*     </label> */}
                        {/*     <input */}
                        {/*       id="contact" */}
                        {/*       type="text" */}
                        {/*       className="w-full rounded-lg border border-zinc-700/50 bg-zinc-800/50 px-3 py-2 text-sm text-white outline-none transition-all placeholder:text-zinc-500 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 md:px-4 md:py-3 md:text-base" */}
                        {/*       value={organization} */}
                        {/*       onChange={(e) => setOrganization(e.target.value)} */}
                        {/*     /> */}
                        {/*     <p className="mt-2 text-xs text-zinc-500 md:text-sm"> */}
                        {/*       We verify and personally connect with each */}
                        {/*       applicant to build a high-quality community. Your */}
                        {/*       information is secure and private. */}
                        {/*     </p> */}
                        {/*   </motion.div> */}
                        {/* )} */}

                        {/* Join Button */}
                        {showJoinButton && (
                          <motion.div
                            key="joinButton"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            className="mt-4"
                          >
                            <button
                              onClick={handleWaitlistJoin}
                              className="w-full rounded-lg bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 md:py-3 md:text-base"
                            >
                              Join Waitlist
                            </button>
                          </motion.div>
                        )}
                      </div>
                    ) : (
                      // Success State
                      <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        className="space-y-4 text-center md:space-y-6"
                      >
                        <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-emerald-500/20 md:h-20 md:w-20">
                          <svg
                            className="h-8 w-8 text-emerald-500 md:h-10 md:w-10"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <div className="space-y-2">
                          <h3 className="text-lg font-bold text-white md:text-xl">
                            You're on the list!
                          </h3>
                          <p className="text-sm text-zinc-400 md:text-base">
                            We'll notify you when we're ready to launch.
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            setIsSuccess(false);
                            setEmail("");
                            setName("");
                            setOrganization("abc");
                          }}
                          className="w-full rounded-lg border border-zinc-700/50 bg-zinc-800/50 px-4 py-2 text-sm font-medium text-white transition-all duration-200 hover:bg-zinc-800 md:py-3 md:text-base"
                        >
                          Add another email
                        </button>
                      </motion.div>
                    )}

                    {/* Login Link */}
                    <button
                      onClick={() => {
                        router.push("/waitlist/login");
                      }}
                      className="z-[100] flex w-full items-center justify-center text-center text-xs font-medium text-indigo-400 transition-colors hover:text-indigo-300 md:text-sm"
                    >
                      Have credentials? Login Instead
                      <HiArrowRight className="ml-1 h-3 w-3 md:h-4 md:w-4" />
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundBeams className="opacity-30" />
    </div>
  );
}
