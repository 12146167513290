import axios from "axios";

// Create an instance for the Next.js API routes
export const internalApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_INTERNAL_API_URL || "http://localhost:5100",
  headers: {
    "Content-Type": "application/json",
  },
});

// Create an instance for the Express backend
export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || "http://localhost:4000",
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
