import React from "react";
import { StarsIcon } from "lucide-react";
import Link from "next/link";

const FeaturebaseFeedbackWidget = () => {
  return (
    <div className="fixed z-[19999] w-full bg-gray-100">
      <Link
        target="_blank"
        href="https://creatr.featurebase.app"
        onClick={() => console.log("Feedback clicked")}
        className="group fixed right-4 top-[15%] flex origin-right -translate-y-[60%] -rotate-90 items-center gap-2 overflow-hidden rounded-t-lg bg-blue-600 px-2 py-2 font-poppins text-white transition-all duration-300 ease-in-out hover:bg-blue-700 active:bg-blue-800 md:top-1/4 md:px-4"
      >
        <div className="absolute inset-0 -translate-x-full bg-gradient-to-r from-transparent via-white/20 to-transparent opacity-0 transition-all duration-500 group-hover:translate-x-full group-hover:opacity-100" />
        <StarsIcon className="relative z-10 h-5 w-5" />
        <span className="relative z-10 hidden font-medium md:block md:text-sm">
          Feedback
        </span>
      </Link>
    </div>
  );
};

export default FeaturebaseFeedbackWidget;
