import { create } from "zustand";

interface WaitlistStore {
  waitlistCount: number;
  setWaitlistCount: (count: number) => void;
}

export const useWaitlistStore = create<WaitlistStore>((set) => ({
  waitlistCount: 1342, // Dummy initial count
  setWaitlistCount: (count) => set({ waitlistCount: count }),
}));
